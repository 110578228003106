import axios from "axios";
import moment from "moment";

export class TimeHelper {


    public static test(apiTime: number) {
        const localTimeStamp = Math.floor(Date.now() / 1000)
        const timeDifference = apiTime - localTimeStamp
        console.log('time difference:', timeDifference);
    }

    public static shouldGetRemoteTime(): boolean {
        const timeDifference = localStorage.getItem('timeDifference')
        if (!timeDifference) {
            return true
        }
        const timeDifferenceSaveDate = localStorage.getItem('timeDifferenceSaveDate')
        if (!timeDifferenceSaveDate) {
            return true
        }
        const localTimeStamp = Math.floor(Date.now() / 1000)
        const secondsSinceSave = localTimeStamp - Number(timeDifferenceSaveDate)
        if (secondsSinceSave < 600) {
            return false
        }
        return true
    }

    public static getCurrentMoment(): moment.Moment {
        const localTimeStamp = moment().unix()
        const timeDifference = Number(localStorage.getItem('timeDifference') ?? '0')
        const currentTimeStamp = localTimeStamp + timeDifference
        const result = moment.unix(currentTimeStamp)

        return result
    }

    public static now(): moment.Moment {
        return this.getCurrentMoment()
    }

    public static async fetchAndCalculateTimeDifference() {

        try {
            if (this.shouldGetRemoteTime() === false) { return }
            const response = await axios.get('https://worldtimeapi.org/api/timezone/Europe/Istanbul');

            const apiTime = response.data.unixtime;

            const localTimeStamp = moment().unix() //Math.floor(Date.now() / 1000)
            const timeDifference = apiTime - localTimeStamp
            console.log('time difference:', timeDifference);
            localStorage.setItem('timeDifference', String(timeDifference))
            localStorage.setItem('timeDifferenceSaveDate', String(localTimeStamp))

        } catch (error) {
            console.error('Zaman API hatası:', error);
            return null;
        }
    }


    public static getNearesttHalfHour(time: moment.Moment = this.getCurrentMoment()): moment.Moment {
        const remainder = 30 - (time.minute() % 30);
        if (remainder === 30) {
            return time.startOf('minute');
        } else {
            return time.add(remainder, 'minutes').startOf('minute');
        }
    }

    public static getNextNearestHalfHour(time: moment.Moment = this.getCurrentMoment()): moment.Moment {
        const remainder = 30 - (time.minute() % 30);
        if (remainder === 30 || remainder === 0) {
            return time.add(30, 'minutes');
        } else {
            return time.add(remainder, 'minutes').startOf('minute');
        }
    }

    public static getRemainingMinutesUntilDayEnd(): number {
        const endOfDay = this.getCurrentMoment().endOf('day');
        const remainingMinutes = endOfDay.diff(this.getCurrentMoment(), 'minutes');
        return remainingMinutes;
    }


    public static formatTime = (time: string) => {
        if (!time) return '';

        const [hour, minute] = time.split(':');
        return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    };

    public static formatTimeForAutomation = (time: Date | null) => {
        if (!time) return '';

        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        const seconds = time.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    };

    public static getStartDate(selectedDays: string[]): string {
        if (selectedDays.length === 0) {
            return '';
        }
        const currentYear = moment().year();
        const currentMonth = moment().month() + 1;
        const today = moment();

    const nextDates = selectedDays
        .map(day => {
            const nextDate = moment(today).day(Number(day)); 
            if (nextDate.isBefore(today)) {
                nextDate.add(1, 'week');
            }
            return nextDate;
        })
        .filter(date => date.isAfter(today) || date.isSame(today));

    const firstDate = moment.min(nextDates);
    return firstDate.isValid() ? firstDate.format('YYYY-MM-DD') : '';
    }

    public static getFinishDate(): string {
        const finishDate = moment().add(1, 'week'); 
        return finishDate.format('YYYY-MM-DD');  
    }



}