import React from 'react'
import { FaTimes } from 'react-icons/fa';
import '../styles/style.css'
import '../styles/CompanyPreview.css'
import { cdnBaseUrl } from '../../api/baseUrl';
interface Props {
    onClose: () => void;
    photoURL: string
    logoURL: string
    companyName: string
    address: string
    segments: string[] | null
}

export const CompanyPreview = ({ onClose, photoURL, logoURL, companyName, address, segments }: Props) => {
    const phone = require('../images/iPhone_14_Pro.png')
    const pin = require('../images/icons/Location--filled.png')
    const logosuz = require ('../images/preview-logosuz.png')
    const logosuzBackground = require('../images/logosuz-background.png')
    return (
        <div>
            <div className='preview-close-container'  style={{backgroundColor:'#F1F5F7'}}>
                <button onClick={onClose} className='preview'><FaTimes style={{ marginRight: '5px', color: 'red' }} /> Kapat</button>
            </div>
            <div className='preview-container'  style={{backgroundColor:'#F1F5F7'}}>
                <h2 style={{fontWeight:'100',fontSize:'40px',color:'#1D1E2599',width:'44%',marginLeft:'60px',lineHeight:'50px'}} className='preview-title'>Restoranın
                    Uygulama Üzerinde
                    Görünüm Örneği</h2>
                <div
                    className="phone-container"
                    style={{
                        width: '100%',
                        maxWidth: '375px',
                        position: 'relative',
                        paddingTop: '50%',
                        backgroundImage: `url(${require('../images/iPhone_14_Pro.png')})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {
                        photoURL ? (
                            <img
                            className="phone-banner"
                            src={cdnBaseUrl + photoURL}
                            style={{
                                width: '84%',
                                height:'200px',
                                maxHeight: '300px',
                                minHeight:'200px',
                                borderTopLeftRadius: '35px',
                                borderTopRightRadius: '35px',
                                position: 'absolute',
                                top: '3%',
                                left: '50%',
                                transform: 'translate(-50%)',
                            }}
                        />
                        ) : (
                            <img
                            className="phone-banner"
                            src={logosuzBackground}
                            style={{
                                width: '84%',
                                height:'250px',
                                minHeight:'200px',
                                maxHeight: '300px',
                                borderTopLeftRadius: '35px',
                                borderTopRightRadius: '35px',
                                position: 'absolute',
                                top: '3%',
                                left: '50%',
                                transform: 'translate(-50%)',
                            }}
                        />
                        )
                    }
                   
                    {
                        logoURL ? (
                            <img
                            className="phone-logo"
                            src={cdnBaseUrl + logoURL}
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '80px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-180%, -160%)',
                                zIndex: '999'
                            }}
                        />
                        ) : (
                            <img
                            className="phone-logo"
                            src={logosuz}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-43%, -155%)',
                                zIndex: '999'
                            }}
                        />
                        )
                    }
                   
                    <div
                        className='preview-content'
                        style={{
                            width: '84%',
                            height:'100%',
                            maxHeight: '365px',
                            backgroundColor: 'white',
                            position: 'absolute',
                            bottom: '0',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            padding: '10px',
                            overflowY:'auto',
                            paddingLeft:'20px',
                            paddingRight:'20px',
                            paddingBottom:'0px'
                        }}
                    >
                        <h3 style={{ fontSize: '32px', fontWeight: '100', marginTop: '22px' }}>{companyName}</h3>
                        <label style={{ fontWeight: '200', fontSize: '16px', color: '#1D1E2599' }}>{address.toLocaleLowerCase()}</label><br/>
                        {segments?.map((segment, i) => (
                            <button style={{ backgroundColor: 'transparent', border: '1px solid #F15F50 ',  fontSize: '12px', fontWeight: '400', borderRadius: '22px', marginBottom: '6px', marginTop: '6px', marginRight: '5px',height:'32px',padding:'5px' }} key={i}>#{segment}</button>
                        ))}

                        <label style={{ fontWeight: '400', fontSize: '14px', color: '#1D1E2599' }}>Mevcut konumunuzun işletmeye uzaklığı:</label>
                        <img style={{ marginBottom: '15px', width: '30px' }} src={pin} /> <label style={{ fontWeight: '600', fontSize: '32px' }}>10 M</label>

                    </div>
                </div>

            </div>

        </div>
    )
}
