// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:1200px) {
    .phone-banner {
        width: 60% !important;
    }
    .preview-content{
        width: 60% !important;
        max-height: 200px !important;
    }
    .preview-content h3{
        font-size: 25px !important;
    }
    .phone-logo{
        transform: translate(-32%, -63%) !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/CompanyPreview.css"],"names":[],"mappings":"AAAA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,qBAAqB;QACrB,4BAA4B;IAChC;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,2CAA2C;IAC/C;AACJ","sourcesContent":["@media screen and (max-width:1200px) {\r\n    .phone-banner {\r\n        width: 60% !important;\r\n    }\r\n    .preview-content{\r\n        width: 60% !important;\r\n        max-height: 200px !important;\r\n    }\r\n    .preview-content h3{\r\n        font-size: 25px !important;\r\n    }\r\n    .phone-logo{\r\n        transform: translate(-32%, -63%) !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
