import React, { useEffect, useState } from 'react';
import '../styles/style.css';
import { MdStop, MdTimer } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ExtendSessionPopup from '../popup/ExtendSessionPopup';
import Modal from 'react-modal';
import StopCampaignSessionPopup from '../popup/StopCampaignSessionPopup';
import moment from 'moment';
import { CampaignDTO } from '@/model/dto/CampaignDTO';

import { Repository } from '../../data/repository/repository';
import {routeLogin, RoutingHelper} from "../../constants/routes";

export default function ActiveCampaignScreen() {
    const bg = require('../images/icons/Sol_color-2.png');
    const lock = require('../images/icons/Locked.png');
    const arrow = require('../images/icons/Text--new-line.png');
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState<string>();
    const [showExtendSessionModal, setShowExtendSessionModal] = useState<boolean>(false);
    const [showStopSessionModal, setShowStopSessionModal] = useState<boolean>(false);
    const [remainingHours, setRemainingHours] = useState<number>(0);
    const [remainingMin, setRemainingMin] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [finishDate, setFinishDate] = useState<Date | null>(null);
    const [discountRate, setDiscountRate] = useState<number>();
    const [isActive, setIsActive] = useState<boolean>(false);

    const company = Repository.getMyCompany()

    const fetchData = async (companyId:String) => {
        try {
            if (companyId) {
                const data = await Repository.getCompany(companyId);
                setCompanyName(data?.name);
            }else{
                RoutingHelper.navigateByUrl(routeLogin)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(company?.id ?? '');
        fetchCampaigns();
    }, []);

    const handleExtendSessionClick = () => {
        setShowExtendSessionModal(true);
    };

    const handleStopSessionClick = () => {
        setShowStopSessionModal(true);
    };

    const handleCloseExtendSessionModal = () => {
        setShowExtendSessionModal(false);
    };

    const handleCloseStopSessionModal = () => {
        setShowStopSessionModal(false);
    };

    const fetchCampaigns = async () => {
        try {
            const campaigns = await Repository.getCampaignsByCompanyId(Repository.getMyCompany()?.id ?? '');
            const now = new Date();
            const activeCampaigns = campaigns?.filter((obj: CampaignDTO) => {
                const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss").toDate();
                const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss").toDate();

                return obj.promotionType === 0 && finishDate > new Date() && (obj.isActive || startDate > new Date());
            }) ?? [];
            
            if (activeCampaigns.length > 0) {
                const activeObjectFinishDate = moment(activeCampaigns[0].finishDate, "DD/MM/YYYY HH:mm:ss").toDate();
                const activeObjectDiscountRate = activeCampaigns[0].discountRate;

                setFinishDate(activeObjectFinishDate);
                setDiscountRate(activeObjectDiscountRate);
                setIsActive(activeCampaigns[0].isActive);
                const remainingTime = calculateRemainingTime(activeObjectFinishDate);
                console.log(remainingTime);
                
               
            }
        } catch (error) {
            console.error(error, 'campaign not found');
        }
    };

    useEffect(() => {
        if (finishDate && isActive) {
            const intervalId = setInterval(() => {
                const remainingTime = calculateRemainingTime(finishDate);
                setRemainingHours(remainingTime.hours);
                setRemainingMin(remainingTime.minutes);

                if (remainingTime.hours === 0 && remainingTime.minutes === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [finishDate]);


    const calculateRemainingTime = (finishDate: Date) => {
        try {
            const now = new Date();
            const remainingTimeInSeconds = Math.max(Math.floor((finishDate.getTime() - now.getTime()) / 1000), 0);

            const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
        const remainingMinutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

            return { hours: remainingHours, minutes: remainingMinutes};
        } catch (error) {
            console.error("Error in calculateRemainingTime:", error);
            return { hours: 0, minutes: 0 };
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <div className='backgroundDiv'>
                            <img src={bg} style={{height:'90vh'}} alt="background" />
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <div className='contentDiv' style={{padding:'20px'}}>
                            <div className='titleLiveRightNov'>
                                <div className='liveRightNov titleOnline' style={{marginLeft:'-20px'}}>
                                    <h2>
                                        🔥 Şu Anda Canlıdasın
                                    </h2>
                                    <button className='stop-session-button' onClick={handleStopSessionClick}><MdStop style={{ color: '#F15F50',width:'35px' }} />Durdur</button>
                                </div>
                                <div className='stop-session-container'>
                                    <Modal
                                        isOpen={showStopSessionModal}
                                        onRequestClose={handleCloseStopSessionModal}
                                        contentLabel="Stop Session Modal"
                                        overlayClassName="custom-overlay stop-session-pop-up"
                                    >
                                        <StopCampaignSessionPopup onClose={handleCloseStopSessionModal} automation = {false} />
                                    </Modal>
                                </div>

                                <hr style={{ width: '550px' }} />
                            </div>
                            <div className='row upperContent'>
                                <p className='liveRightNowUpperParagraph'>Şu anda Yerim uygulamasında aktif olarak görünüyorsunuz.</p>
                                <div className='col-sm-6'>
                                    <p>Ne kadar daha online kalacağım?</p>
                                    <button style={{width:'320px'}} className='timerButton'><MdTimer style={{ color: '#F15F50' }} /> {remainingHours}<span style={{ color: '#1D1E2533' }}>Sa</span> : {remainingMin}<span style={{ color: '#1D1E2533' }}>Dk</span></button>
                                </div>
                                <div className='col-sm-6'>
                                    <p>Ne zaman kapanacak?</p>
                                    <button style={{ border: '2px dashed #1D1E25 ' }}>
                                        <img style={{width:'25px', marginRight:'5px'}}  src={lock} />
                                        {finishDate?.toLocaleString([], { hour: '2-digit', minute: '2-digit',hour12:false })}
                                    </button>

                                </div>
                                <div>
                                    <p>Aktif indirim oranı</p>
                                    <button style={{ border: '2px dashed #1D1E25 ' }}><img style={{width:'25px'}} src={lock} alt="lock" /> %{discountRate} 🤩</button>
                                </div>

                                <div className='companyBecomeOnlineButton'>
                                    <p>Çevrimiçi oturumunuzu uzatmak ister misiniz?</p>
                                    <button onClick={handleExtendSessionClick}><img src={arrow} alt="arrow" /> Oturumu Uzat</button>
                                </div>
                                <div className='stop-session-container'>
                                    <Modal
                                        isOpen={showExtendSessionModal}
                                        onRequestClose={handleCloseExtendSessionModal}
                                        contentLabel="Extend Session Modal"
                                        overlayClassName="custom-overlay stop-session-pop-up"
                                    >
                                        <ExtendSessionPopup  onClose={handleCloseExtendSessionModal} />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
