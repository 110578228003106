import { Checkbox } from '@mui/material';
import { TimeHelper } from '../../helpers/TimeHelper';
import React, { useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Repository } from '../../data/repository/repository';
import InputMask from 'react-input-mask';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';


interface Props {
    onClose: () => void;
    startTime: string;
    finishTime: string
    startDate: string;
    finishDate: string;
    automationId: string
}


export default function OpenMissingDayPopup({ onClose, startTime, finishTime, startDate, finishDate, automationId }: Props) {
    const leftColumnBackground = require('../images/weeklyplan.png');
    const [activeTab, setActiveTab] = useState('GünlükPlan');
    const arrow = require('../images/icons/Text--new-line.png');

    const [openingTime, setOpeningTime] = useState<Date | null>(null);
    const [closingTime, setClosingTime] = useState<Date | null>(null);
    const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const currentMoment = TimeHelper.now();
    const formattedDate = currentMoment.format("D MMMM dddd YYYY");

    const getBackgroundStyle = () => {
        switch (activeTab) {
            case 'GünlükPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '110vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            case 'HaftalıkPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '200vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    };

    const handleBecomeOnline = async () => {
        if (!checkboxChecked) {
            toast.error('Lütfen boş alanları doldurun.')
            return;
        }

        if (selectedDiscountValue === null || selectedDiscountValue < 0) {
            toast.error("Lütfen indirim oranı seçiniz")
            return;
        }

        setErrorMessage(null);

        const campaignData = {
            automationId: automationId,
            companyId: Repository.getMyCompany()?.id ?? '',
            promotionType: 0,
            startDate: openingTime ? moment(openingTime).format('YYYY-MM-DDTHH:mm:ss') : TimeHelper.now().format('YYYY-MM-DDTHH:mm:ss'),
            finishDate: closingTime ? moment(closingTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            discountRate: selectedDiscountValue || 0,
        };

        try {
            const result = await Repository.createCampaign(campaignData);
            if (result) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDiscountValueClick = (value: number) => {
        setSelectedDiscountValue(value);
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxChecked(e.target.checked);
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img style={getBackgroundStyle()} src={leftColumnBackground} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div style={{ padding: '40px' }}>
                        <div className='titleLiveRightNov'>
                            <div className='liveRightNov titleOnline stopSession'>
                                <h2 style={{ marginTop: '20px', marginLeft: '-20px' }}>
                                    İndirim Aç
                                </h2>
                                <button onClick={onClose} style={{ marginTop: '20px' }}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                            </div>
                            <p style={{ marginLeft: '-20px' }}>Bugün {formattedDate}</p>

                            <hr style={{ width: '100%', marginLeft: '-20px', marginTop: '40px', marginBottom: '40px' }} />
                        </div>
                        <div className='row weekly-plan-content' style={{ height: 'unset' }}>
                            <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>

                                    <div style={{ marginRight: '60px' }}>
                                        <p>Ne zaman açılsın?</p>
                                        <TimePicker
                                            value={openingTime}
                                            onChange={(newValue) => setOpeningTime(newValue)}
                                            ampm={false}

                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    border: '1px dashed #1D1E2533',
                                                    borderRadius: '12px',
                                                    padding: '16px',
                                                    height: '82px',
                                                    marginRight: '60px'
                                                }
                                            }}
                                        />

                                    </div>

                                    <div
                                        style={{
                                            height: '120px',
                                            width: '1px',
                                            backgroundColor: '#1D1E2533',
                                            marginRight: '60px'
                                        }}
                                    ></div>

                                    <div>
                                        <p>
                                            Ne zaman kapansın?
                                        </p>
                                        <TimePicker
                                            value={closingTime}
                                            onChange={(newValue) => setClosingTime(newValue)}
                                            ampm={false}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    border: '1px dashed #1D1E2533',
                                                    borderRadius: '12px',
                                                    padding: '16px',
                                                    height: '82px'
                                                }
                                            }}
                                        />

                                    </div>
                                </LocalizationProvider>
                            </div>

                            <hr style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />

                            <div>
                                <p className='upperContent-p'>Ne kadarlık bir indirim açılsın?</p>
                                <div className='saleButtons'>
                                    {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
                                        <button style={{marginBottom:'25px'}}
                                            key={value}
                                            onClick={() => handleDiscountValueClick(value)}
                                            className={selectedDiscountValue === value ? 'selected-button' : 'normal-button'}>
                                            {`${value}%`} {selectedDiscountValue === value && '🤩'}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className='contract' style={{ padding: '24px' }}>
                                <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} sx={{
                                    '&.Mui-checked': {
                                        color: '#F15F50',
                                    },
                                    '&.Mui-checked:hover': {
                                        backgroundColor: 'rgba(255, 165, 0, 0.08)',
                                    }
                                }} style={{ transform: 'scale(1.5)', marginRight: '10px' }} ></Checkbox>
                                <p>Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz olarak sunacağımı kabul ederim.</p>
                            </div>
                            <div className='companyBecomeOnlineButton'>
                                <button onClick={handleBecomeOnline} style={{ marginTop: '20px' ,backgroundColor:'#F15F50',fontSize:'16px',paddingTop:'16px',paddingLeft:'22px',paddingBottom:'16px',paddingRight:'22px'}}>
                                    <img src={arrow} alt="arrow" /> İndirim Aç
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
