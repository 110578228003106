import {CompanyLoginResultDTO} from "@/model/dto/CompanyLoginResultDTO";
import {LoginRequest} from "@/model/request/LoginRequest";
import ApiService from "../services/remote/ApiService";
import LocalStorageService from "../services/local/LocalStorageService";
import {CreateCampaignRequest} from "../../model/request/CreateCampaignRequest";
import {CampaignDTO, PromotionType} from "../../model/dto/CampaignDTO";
import {QRCodeDTO} from "../../model/dto/QRCodeDTO";
import {NotificationDataRequest} from "../../model/request/NotificationDataRequest";
import {CreateDocumentRequest} from "../../model/request/CreateDocumentRequest";
import {CityDTO} from "../../model/dto/CityDTO";
import {CompanyDTO} from "../../model/dto/CompanyDTO";
import {CreateCompanyRequest} from "@/model/request/CreateCompanyRequest";
import {CampaignDataRequest} from "../../model/request/CampaignDataRequest";
import {DocumentUrlDTO} from "../../model/dto/DocumentUrlDTO";
import {NotificationDTO} from "../../model/dto/NotificationDTO";
import {DocumentDTO} from "../../model/dto/DocumentDTO";
import {DistrictDTO} from "../../model/dto/DistrictDTO";
import {NeighborhoodDTO} from "../../model/dto/NeighborhoodDTO";
import {StreetDTO} from "../../model/dto/StreetDTO";
import {ImageDTO} from "../../model/dto/ImageDTO";
import {GuestDTO} from "@/model/dto/GuestDTO";
import {GuestSessionDTO} from "@/model/dto/GuestSessionDTO";
import {GuestLoginResultDTO} from "@/model/dto/GuestLoginResultDTO";
import {cdnBaseUrl} from "../../api/baseUrl";
import moment from "moment/moment";
import {TimeHelper} from "../../helpers/TimeHelper";
import { SendRecoverPasswordCodeRequest } from "../../model/request/SendRecoverPasswordCodeRequest";
import { CreateAutomationRequest } from "@/model/request/CreateAutomationRequest";
import { AutomationDTO } from "@/model/dto/AutomationDTO";
import { UpdateCampaignRequest } from "../../model/request/UpdateCampaignRequest";

export class Repository {


    // region company & session
    static async login (requestData : LoginRequest) : Promise <CompanyLoginResultDTO |  null> {
        const response = await ApiService.login(requestData)
        if (response) {
            LocalStorageService.setCompany(response.company)
            LocalStorageService.setToken(response.jwtToken)
            LocalStorageService.setSession(response.companySession)
        }
        return response
    }

    static hasValidSession() : boolean {
        return LocalStorageService.getSession() != null && LocalStorageService.getCompany() != null && LocalStorageService.getToken() != null
    }

    static async sendRecoveryCode(request: SendRecoverPasswordCodeRequest): Promise<boolean>{
        const response = await ApiService.sendRecoveryCode(request)
        return response
    }

    static async verifyRecoveryCode(email: string, code: string, newPassword: string): Promise<boolean> {
        const response = await ApiService.verifyRecoveryCode(email,code,newPassword)
        return response
    }

    static async refreshToken() : Promise<CompanyLoginResultDTO | null> {
        const currentSession = LocalStorageService.getSession()
        if(!currentSession) {
            return null
        }
        const response = await ApiService.refreshToken(currentSession.refreshToken.token);
        if (response) {
            LocalStorageService.setToken(response.jwtToken);
            LocalStorageService.setSession(response.companySession);
            LocalStorageService.setCompany(response.company)
            return response;
        }
        return null;
    }

    static async logOut() : Promise<boolean> {
        //const session = LocalStorageService.getSession();
        //await ApiService.logout(session?.id ?? '')
        this.clearSessionData()
        return true;
    }

    static clearSessionData() {
        LocalStorageService.clearLocalData()
    }

    static getToken(): string | null {
        return LocalStorageService.getToken()
    }

    static setMyCompany(company:CompanyDTO) {
        LocalStorageService.setCompany(company)
    }

    static getMyCompany() : CompanyDTO | null {
        return LocalStorageService.getCompany()
    }

    static async getCompany(companyId: String, forceRemote:boolean=true): Promise<CompanyDTO | null> {

        if(!forceRemote) {
            const localCompany = LocalStorageService.getCompany()
            if(localCompany) {
                return localCompany
            }
        }

        const response = await ApiService.getCompany(companyId)
        return response

    }

    static async registerCompany(companyData: CreateCompanyRequest): Promise<CompanyDTO | null> {
        const response = await ApiService.registerCompany(companyData)
        return response
    }

    static async updateCompany(companyData: CreateCompanyRequest, companyId: String): Promise <CompanyDTO | null > {
        const response = await ApiService.updateCompany(companyData,companyId)
        return response
    }

    // endregion




    // region guest user
    static async loginAsGuest() : Promise<GuestLoginResultDTO | null> {
        console.warn('login as guest')
        const response = await ApiService.createGuest()
        if(!response) {
            return null
        }

        LocalStorageService.setGuestUser(response)
        const result = await ApiService.loginGuest()

        if(!result) {
            return null
        }

        LocalStorageService.setGuestSession(result.session)
        LocalStorageService.setGuestToken(result.jwtToken)

        return result
    }

    static getGuestUser (): GuestDTO | null {
        return LocalStorageService.getGuestUser()
    }

    static getGuestSession (): GuestSessionDTO | null {
        return LocalStorageService.getGuestSession()
    }

    static getGuestToken() : string | null {
        return LocalStorageService.getGuestToken();
    }

    // endregion


    // region campaigns

    static async createFlashDeal (requestData: CreateCampaignRequest) : Promise <CampaignDTO | null> {
        const response = await ApiService.createFlashDeal(requestData);
        return response
    }

    static async createCampaign(campaignData: CampaignDataRequest):Promise <CampaignDTO | null>  {
        const response = await ApiService.createCampaign(campaignData)
        return response
    }

    static async getCampaignsByCompanyId(companyId: String): Promise <[CampaignDTO] | null >  {
        const response = await ApiService.getCampaignsByCompanyId(companyId)
        return response
    }

    static async fetchMyCampaigns() : Promise<[CampaignDTO] | null> {
        const companyId = LocalStorageService.getCompany()?.id;
        if(!companyId) {
            return null
        }
        const campaigns = await ApiService.getCampaignsByCompanyId(companyId)
        this.setLocalCampaigns(campaigns ?? [])

        return campaigns
    }


    // region campaign local check
    // these methods will be used after switching to repository system for companies
    private static setLocalCampaigns(campaigns: CampaignDTO[]) {
        LocalStorageService.setCampaigns(campaigns)
    }

    static getLocalCampaigns (): CampaignDTO[] {
        const campaigns = LocalStorageService.getCampaigns()
        const filteredCampaigns = campaigns?.filter((obj: CampaignDTO) => {
            const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss")
            const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss")

            return finishDate.isAfter(TimeHelper.now()) && (obj.isActive || startDate.isAfter(TimeHelper.now()));
        }) ?? new Array<CampaignDTO>();
        this.setLocalCampaigns(filteredCampaigns)
        return filteredCampaigns;
    }

    public static getLocalActiveDiscount() : CampaignDTO | null {
        const discount = this.getLocalCampaigns().find((c:CampaignDTO) => c.promotionType == PromotionType.discount)
        if(!discount) { return null }
        return discount
    }

    public static getLocalActiveFlashDeal() : CampaignDTO | null {
        const deal = this.getLocalCampaigns().find((c:CampaignDTO) => c.promotionType == PromotionType.flashDeal)
        if(!deal) { return null }
        return deal
    }
    // endregion


    static async getCampaignsByCityId(cityId: String): Promise<[CampaignDTO] | null> {
        return await ApiService.getCampaignsByCityId(cityId)
    }

    static async PutCampaignsFinish(campaignId: String): Promise <boolean> {
        const response = await ApiService.PutCampaignsFinish(campaignId)
        return response;
    }


    static async PutProlong(campaignId: String, selectedValue: String): Promise <boolean>{
        const response = await ApiService.PutProlong(campaignId,selectedValue)
        return response;
    }


    // endregion

    // region qr codes

    static async getQrCodeByCampaignId(campaignId: string, companyId: string, status: string) {
        const response = await ApiService.getCampaignQRCodesByStatus(campaignId, companyId, status);
        return response
    }

    static async getQrCodeByCode(code: String): Promise<QRCodeDTO | null> {
        const response = await ApiService.getQRCodeByCode(code);
        return response
    }

    static async getQrCodeByQrCodeId(qrCodeId: String): Promise<QRCodeDTO | null> {
        const response = await ApiService.getQRCodeById(qrCodeId);
        return response;
    }

    static async consumeQrCode(code: String): Promise<QRCodeDTO | null> {
        const response = await ApiService.consumeQRCode(code);
        return response;
    }

    // endregion




    // region documents

    static async getAppDocuments():Promise <[DocumentDTO] | null>  {

        const localData = LocalStorageService.getAppDocuments()
        if(localData != null && localData.length > 0 && LocalStorageService.getLocalAppDocumentsVersion() >= (LocalStorageService.getVersions()?.documents ?? 100000)) {
            return localData;
        }

        const response = await ApiService.fetchAppDocuments()
        LocalStorageService.setAppDocuments(response)
        LocalStorageService.setLocalAppDocumentsVersion(LocalStorageService.getVersions()?.documents ?? 0)
        return response
    }

    static getAppDocumentUrl(code:string) : string {
        return (cdnBaseUrl + LocalStorageService.getAppDocuments()?.find(d => d.code == code)?.documentUrl)
    }

    static async createAppDocument(request: CreateDocumentRequest): Promise <boolean>  {
        const response = await ApiService.createAppDocument(request)
        return response
    }

    static async uploadDocument(file: File): Promise<DocumentUrlDTO | null> {
        const response = await ApiService.uploadDocument(file)
        return response
    }

    static async deleteAppDocument(documentCode: String): Promise <boolean> {
        const response = await ApiService.deleteAppDocuments(documentCode)
        return response
    }

    static async updateAppDocument(documentData: any):Promise <DocumentDTO | null > {
        const response = await ApiService.updateAppDocument(documentData)
        return response
    }

    static async postInAppContent(content: string): Promise<string | null | undefined> {
        const response = await ApiService.postInAppContent(content)
        return response
    }

    static async postCompanyAgreement (aggrement:string, companyId:string) : Promise <boolean | null> {
        const response = await ApiService.postCompanyAgreement(aggrement,companyId)
        return response
    }

    static async getCompanyAgreement (companyId:string): Promise <string | null> {
        const response = await ApiService.getCompanyAgreement(companyId)
        return response
    }


    // endregion


    // region notifications

    static async sendNotification(notificationData: NotificationDataRequest):Promise<NotificationDTO | null>{
        const response = await ApiService.sendNotification(notificationData)
        return response
    }

    static async fetchNotifications(): Promise<[NotificationDTO] | null> {
        const response = await ApiService.getNotifications()
        return response
    }


    // endregion


    // region location

    static async getCities(): Promise<[CityDTO] | null> {
        const localData = LocalStorageService.getCities()
        if(localData != null && localData.length > 0 && LocalStorageService.getLocalCitiesVersion() >= (LocalStorageService.getVersions()?.cities ?? 0)) {
            return localData;
        }

        const response = await ApiService.getCities()
        if(response != null) {
            LocalStorageService.setCities(response)
            LocalStorageService.setLocalCitiesVersion(LocalStorageService.getVersions()?.cities ?? 0)
        }

        return response
    }

    static async getDistrictById(cityId: String): Promise <[DistrictDTO] | null > {
        const response = await ApiService.getDistrictById(cityId)
        return response
    }

    static async getNeighboorhoodDistrictById(districtId: String): Promise <[NeighborhoodDTO] | null> {
        const response = await ApiService.getNeighboorhoodDistrictById(districtId)
        return response
    }

    static async getStreetsById(neighboorhoodId: String): Promise <[StreetDTO] | null > {
        const response = await ApiService.getStreetsById(neighboorhoodId)
        return response
    }

    // endregion


    // region media

    static async uploadFile(file: File):Promise<ImageDTO | null> {
        const response = await ApiService.uploadFile(file)
        return response;
    }

    static async getImage(imageUrl: string) {
        const response = await ApiService.getImage(imageUrl)
        return response;
    }


    // endregion








    // region end-user services

    static async sendSmsCode(phoneNumber: string): Promise<boolean> {
        const response = await ApiService.sendSmsCode(phoneNumber)
        return response
    }

    static async verifySmsCode(phoneNumber: string, code: string): Promise<boolean | null> {
        const response = await ApiService.verifySmsCode(phoneNumber,code)
        return response
    }

    static async deleteAccount(phoneNumber: string, code: string): Promise<number | string | any | null> {
        const response = await ApiService.deleteAccount(phoneNumber,code)
        return response
    }

    // endregion





    static async fetchRepoVersions() {
        const result = await ApiService.fetchRepoVersions();
        LocalStorageService.setVersions(result);
    }



    static async postAutomation(data : CreateAutomationRequest): Promise <AutomationDTO | null>{
        const response = await ApiService.postAutomation(data)
        return response
    }


    static async updateAutomation(data: UpdateCampaignRequest,campaignId:string): Promise <boolean>{
        const response = await ApiService.updateAutomation(data,campaignId)
        return response
    }

    static async cancelAutomation (campaignId:string): Promise <boolean>{
        const response = await ApiService.cancelAutomation(campaignId)
        return response
    }

    static async getActiveAutomation (campaignId:string):Promise<AutomationDTO | null>{
        const response = await ApiService.getActiveAutomation(campaignId)
        return response
    }
}