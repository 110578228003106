import {CompanyDTO} from '@/model/dto/CompanyDTO';
import {CityDTO} from '../../../model/dto/CityDTO';
import {CompanySessionDTO} from '@/model/dto/CompanySessionDTO';
import {GuestDTO} from '@/model/dto/GuestDTO';
import {GuestSessionDTO} from "@/model/dto/GuestSessionDTO";
import {StorageHelper} from "../../../helpers/StorageHelper";
import {VersionsDTO} from "@/model/dto/VersionsDTO";
import {DocumentDTO} from "@/model/dto/DocumentDTO";
import {CampaignDTO} from "@/model/dto/CampaignDTO";
import moment from "moment/moment";
import { AutomationDTO } from '@/model/dto/AutomationDTO';

class LocalStorageService {

    //region guest

    public static setGuestUser (guest : GuestDTO | null) {
        StorageHelper.setObject('guest',guest)
    }

    public static getGuestUser(): GuestDTO | null {
        return StorageHelper.getObject('guest')
    }

    public static getGuestSession() : GuestSessionDTO | null {
        return StorageHelper.getObject('guest_session')
    }

    public static setGuestSession(session : GuestSessionDTO | null) {
        StorageHelper.setObject('guest_session',session);
    }

    public static setGuestToken(token : string | null) : void {
        StorageHelper.setString('guest_token',token);
    }

    public static getGuestToken() : string | null {
        return StorageHelper.getString('guest_token')
    }

    //endregion

    //region versions
    public static setVersions(versions:VersionsDTO | null) {
        StorageHelper.setObject('versions',versions);
    }

    public static getVersions() : VersionsDTO | null {
        return StorageHelper.getObject('versions')
    }

    public static setLocalAppDocumentsVersion(version:number) {
        StorageHelper.setString('app_documents_version', version.toString());
    }

    public static getLocalAppDocumentsVersion() : number {
        return parseInt(StorageHelper.getString('app_documents_version') ?? '-1');
    }

    //endregion

    //region app documents

    public static setAppDocuments(documents:[DocumentDTO] | null) {
        StorageHelper.setObject('documents',documents);
    }

    public static getAppDocuments() : [DocumentDTO] | null {
        return StorageHelper.getObject('documents')
    }

    //endregion


    //region locations
    static getCities() : [CityDTO] | null {
        return StorageHelper.getObject('cities')
    }

    static setCities(cities:[CityDTO])  {
        StorageHelper.setObject('cities',cities)
    }

    public static setLocalCitiesVersion(version:number) : void {
        StorageHelper.setString('local_cities_version', version.toString());
    }

    public static getLocalCitiesVersion() : number {
        return parseInt(StorageHelper.getString('local_cities_version') ?? '-1');
    }

    //endregion

    //region company & session

    public static setToken (token:string | null) : void {
        StorageHelper.setString('token',token);
    }

    public static getToken () : string | null {
        return StorageHelper.getString('token')
    }

    public static setCompany (company:CompanyDTO | null){
        StorageHelper.setObject('company',company);
    }


    public static getCompany(): CompanyDTO | null {
        return StorageHelper.getObject('company');
    }

    public static setSession(session:CompanySessionDTO | null) {
        StorageHelper.setObject('session',session);
    }

    public static getSession(): CompanySessionDTO | null {
        return StorageHelper.getObject('session')
    }

    //endregion

    // region campaign

    public static setCampaigns(campaigns:CampaignDTO[] | null) : void {
        StorageHelper.setObject('campaigns',campaigns);
    }

    public static getCampaigns() : CampaignDTO[] | null {
        return StorageHelper.getObject('campaigns');
    }

    public static appendCampaign(campaign:CampaignDTO) : void {
        let campaigns = this.getCampaigns()
        if(campaigns == null) {
            campaigns = [campaign]
        }
        else {
            campaigns.push(campaign);
        }
        this.setCampaigns(campaigns);
    }

    public static removeCampaign(campaignId:String) : void {
        const campaigns = this.getCampaigns();
        if (campaigns != null) {
          const updatedCampaigns = campaigns.filter(campaign => campaign.id !== campaignId);
          this.setCampaigns(updatedCampaigns);
        }
    }

    public static getActiveDiscount(): CampaignDTO | null {
        const discount = StorageHelper.getObject('active_discount');
        return discount
    }

    public static getActiveFlashDeal() : CampaignDTO | null {
        return StorageHelper.getObject('active_flash_deal');
    }


    // endregion


    public static clearLocalData () {
        this.setCompany(null)
        this.setSession(null)
        this.setToken(null)
        this.setGuestUser(null)
        this.setGuestSession(null)
        this.setGuestToken(null)

    }

    public static setActiveAutomation (activeAutomation:AutomationDTO | null) {
        const activeAutomationInfo = localStorage.setItem('activeAutomation',JSON.stringify(activeAutomation))
        return activeAutomationInfo
    }   

    public static getActiveAutomation ():AutomationDTO | null {
        const json = localStorage.getItem('activeAutomation') ?? ''
        const activeAutomation = JSON.parse(json)
        return activeAutomation
    }
    /*
    public static setRole (role:string) {
        return localStorage.setItem('role',role)
    }


    public static getRole () {
        return localStorage.getItem('role')
    }*/

    /*
    public static setRefreshToken (refreshToken:string) {
        localStorage.setItem('refreshToken',refreshToken)
    }


    public static getRefreshToken () {
        return localStorage.getItem('refreshToken')
    }*/






}
  
export default LocalStorageService


