import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeHelper } from '../../helpers/TimeHelper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Repository } from '../../data/repository/repository';
import { toast } from 'react-toastify';

const daysOfWeek = [
  { label: 'Pazartesi', value: 1 },
  { label: 'Salı', value: 2 },
  { label: 'Çarşamba', value: 3 },
  { label: 'Perşembe', value: 4 },
  { label: 'Cuma', value: 5 },
  { label: 'Cumartesi', value: 6 },
  { label: 'Pazar', value: 0 },
];

export default function CreateWeeklyPlanningScreen() {
  const arrow = require('../images/icons/Text--new-line.png');

  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [openingTime, setOpeningTime] = useState<Date | null>(null);
  const [closingTime, setClosingTime] = useState<Date | null>(null);
  const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedStartTime, setselectedStartTime] = useState<moment.Moment | null>(null);
  const [selectedFinishTime, setSelectedFinishTime] = useState<moment.Moment | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedDays((prev) =>
      prev.includes(value) ? prev.filter((day) => day !== value) : [...prev, value]
    );
  };


  const handleDiscountValueClick = (value: number) => {
    setSelectedDiscountValue(value);
  };


  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(e.target.checked);
  };


  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const handleSubmit = async () => {
    if (!checkboxChecked) {
      setErrorMessage('Lütfen boş alanları doldurun.')
      return;
    }

    if (selectedDiscountValue === null || selectedDiscountValue < 0) {
      toast.error("Lütfen indirim oranı seçiniz")
      return;
    }

    if (selectedStartTime !== null && selectedStartTime >= selectedFinishTime!) {
      toast.error("Başlama tarihi bitiş tarihinden büyük veya eşit olamaz")
      return;
    }

    setErrorMessage(null);

    const today = new Date();
    const startDate = formatDate(today);

    const oneWeekFromToday = new Date();
    oneWeekFromToday.setDate(today.getDate() + 7);
    const finishDate = formatDate(oneWeekFromToday);


    const data = {
      companyId: Repository.getMyCompany()?.id ?? '',
      activeDays: selectedDays.map(String),
      startTime: TimeHelper.formatTimeForAutomation(openingTime) || '',
      finishTime: TimeHelper.formatTimeForAutomation(closingTime) || '',
      startDate,
      finishDate,
      discountRate: selectedDiscountValue || 0,

    };


    try {
      const response = await Repository.postAutomation(data);

      if (response) {
        console.log('İşlem başarılı:', response);
        window.location.reload();
      } else {
        setErrorMessage('Plan sırasında bir hata oluştu.');
      }
    } catch (error) {
      console.error('Plan sırasında bir hata oluştu:', error);
      setErrorMessage('Plan sırasında bir hata oluştu.');
    }
  };
  return (
    <div>
      <h2 style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Hangi günler indirim açılsın?</h2>
      <FormGroup style={{ padding: '10px' }} row>
        {daysOfWeek.map((day) => (
          <FormControlLabel
            key={day.value}
            className={selectedDays.includes(day.value.toString()) ? 'active-checkbox' : 'custom-checkbox-label'}
            control={
              <Checkbox
                checked={selectedDays.includes(day.value.toString())}
                onChange={handleChange}
                value={day.value}
                sx={{
                  '&.Mui-checked': {
                    color: '#F15F50',
                  },
                  '&.Mui-checked:hover': {
                    backgroundColor: 'rgba(255, 165, 0, 0.08)',
                  }
                }}
              />
            }
            label={day.label}
          />
        ))}
      </FormGroup>
      <hr style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />
      <div style={{ display: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div>
            <h2 style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Ne zaman açılsın?</h2>
            <TimePicker
              value={openingTime}
              onChange={(newValue) => setOpeningTime(newValue)}
              ampm={false}

              sx={{
                '& .MuiInputBase-root': {
                  border: '1px dashed #1D1E2533',
                  borderRadius: '12px',
                  padding: '16px',
                  height: '82px',
                  marginRight: '60px'
                }
              }}
            />
          </div>
          <div
            style={{
              height: '120px',
              width: '1px',
              backgroundColor: '#1D1E2533',
              marginRight: '60px'
            }}
          ></div>
          <div>
            <h2 style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Ne zaman kapansın?</h2>
            <TimePicker
              value={closingTime}
              onChange={(newValue) => setClosingTime(newValue)}
              ampm={false}
              sx={{
                '& .MuiInputBase-root': {
                  border: '1px dashed #1D1E2533',
                  borderRadius: '12px',
                  padding: '16px',
                  height: '82px'
                }
              }}
            />
          </div>
        </LocalizationProvider>

      </div>

      <hr style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />

      <div>
        <p className='upperContent-p'>Ne kadarlık bir indirim açılsın?</p>
        <div>
          {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
            <button
              key={value}
              onClick={() => handleDiscountValueClick(value)}
              className={selectedDiscountValue === value ? 'selected-discount-button' : 'discount-button '}>
              {`${value}%`} {selectedDiscountValue === value && '🤩'}
            </button>
          ))}
        </div>
      </div>
      <hr style={{ width: '100%', marginBottom: '40px', marginTop: '40px' }} />
      <div className='contract'>
        <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} />

        <p>Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz olarak sunacağımı kabul ederim.</p>
      </div>
      {errorMessage !== null ? <p style={{ color: 'red' }}>{errorMessage}</p> : ''}

      <div className='companyBecomeOnlineButton'>
        <button style={{ marginBottom: '40px' }} onClick={handleSubmit}><img src={arrow} alt="arrow" />Online Ol
        </button>
      </div>

    </div>
  );
}
