import { useState } from "react";
import { CreateCompanyRequest, CreateCompanyRequestFactory } from "../model/request/CreateCompanyRequest";
import { Repository } from "../data/repository/repository";

export const FindChangedFields = () => {
  const [formData, setFormData] = useState<CreateCompanyRequest | null>(
    CreateCompanyRequestFactory.build(Repository.getMyCompany())
  );

  const tabFields: { [key: string]: string[] } = {
    formGroupTaxInfo: [
      'taxCertificate.identificationNumber',
      'taxCertificate.taxNo',
      'taxCertificate.taxOffice',
      'taxCertificate.taxPayerName',
      'taxCertificate.tradeName',
    ],
    formGroupCompanyInfo: [
      'name',
      'type',
      'segments',
      'delegate.delegateName',
      'delegate.delegateSurname',
      'delegate.delegatePosition',
      'phoneNumber',
      'ownerPhoneNumber',
      'workingHours',
      'capacity',
      'webSiteLink',
      'photoUrl',
      'logoUrl',
    ],
    formGroupAddressInfo: [
      'address.city',
      'address.district',
      'address.neighborhood',
      'address.street',
      'address.buildingNo',
      'location.longitude',
      'location.latitude',
    ],
    formGroupPassWordInfo: ['email', 'password'],
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const findChangedFields = (originalData: any, updatedData: any, tabFields: string[]) => {
    const changedFields: any = {};

    tabFields.forEach((field) => {
      const originalValue = getNestedValue(originalData, field);
      const updatedValue = getNestedValue(updatedData, field);

      if (originalValue !== updatedValue) {
        changedFields[field] = updatedValue;
      }
    });
    return changedFields;
  };

  const setNestedField = (obj: any, path: string, value: any) => {
    const keys = path.split('.');
    let current = obj;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = current[key] || {};
        current = current[key];
      }
    });
  };

  const preparePayload = (changedFields: any) => {
    const payload: any = {};

    Object.keys(changedFields).forEach((fieldPath) => {
      setNestedField(payload, fieldPath, changedFields[fieldPath]);
    });

    return payload;
  };

  return { tabFields, findChangedFields, preparePayload };
};
