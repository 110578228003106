import React, { useEffect, useState } from 'react';
import { FaPaperPlane, FaTimes, FaCircle } from 'react-icons/fa';
import { Button, Grid, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { cdnBaseUrl } from '../../api/baseUrl';
import { Repository } from '../../data/repository/repository';
import { DocumentDTO } from '../../model/dto/DocumentDTO';
import { ImageDTO } from '@/model/dto/ImageDTO';


export default function DocumentsScreen() {
    const bg = require('../images/Sol_color.png');
    const arrow = require('../images/icons/Text--new-line.png')
    const [documents, setDocuments] = useState<DocumentDTO[]>([]);
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<ImageDTO | string | null>();
    const [file, setFile] = useState<File | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<string>('pdf');
    const [isConfidential, setIsConfidential] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');
    const [documentCode, setDocumentCode] = useState<string>('');
    const [deleteDocumentId, setDeleteDocumentId] = useState<string>('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [updateDocumentId, setUpdateDocumentId] = useState<string>('');
    const [updateDocumentCode, setUpdateDocumentCode] = useState<string>('');
    const [updateDocumentType, setUpdateDocumentType] = useState<number>(0);
    const [showDocumentsList, setShowDocumentsList] = useState<boolean>(true);



    const handleCreateDocument = async () => {
        try {

            const formData = {
                title: title,
                code: documentCode,
                clientListing: isConfidential,
                content: content,
                documentUrl: '',
                type: documentType === 'pdf' ? 0 : 1
            };

            if (documentType === 'pdf' && file) {
                const document = await Repository.uploadDocument(file);
                formData.documentUrl = document?.url ?? '';
            }
            await Repository.createAppDocument(formData);
            const newDocuments = await Repository.getAppDocuments() ?? [];
            setDocuments(newDocuments);

            setTitle('');
            setImageUrl(null);
            setShowCreateForm(false);
        } catch (error) {
            console.error('Belge oluşturulurken bir hata oluştu:', error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await Repository.deleteAppDocument(deleteDocumentId);
            const newDocuments = await Repository.getAppDocuments() ?? [];
            setDocuments(newDocuments);
            setDeleteDocumentId('');
            setOpenDeleteDialog(false);
        } catch (error) {
            console.error('Belge silinirken bir hata oluştu:', error);
        }
    };

    const handleUpdate = async () => {
        try {
            const documentData = {
                id: updateDocumentId,
                title: title,
                content: content,
                clientListing: isConfidential,
                code: updateDocumentCode,
                type: updateDocumentType,
                documentUrl: imageUrl
            };

            if (updateDocumentType === 0 && file) {
                const document = await Repository.uploadDocument(file) ;
                documentData.documentUrl = document?.url ?? '';
            }

            await Repository.updateAppDocument(documentData);
            const newDocuments = await Repository.getAppDocuments() ?? [];
            setDocuments(newDocuments);
            setUpdateDocumentId('');
            setTitle('');
            setContent('');
            setIsConfidential(false);
            setDocumentCode('');
            setShowCreateForm(false);
            setShowUpdateForm(false);
            setShowDocumentsList(true);
        } catch (error) {
            console.error('Belge güncellenirken bir hata oluştu:', error);
        }
    };

    const openUpdateForm = (documentId: string) => {

        const documentToUpdate = documents.find(document => document.id === documentId);

        if (documentToUpdate) {
            setTitle(documentToUpdate.title);
            setContent(documentToUpdate.content);
            setIsConfidential(documentToUpdate.clientListing);
            setUpdateDocumentId(documentId);
            setDocumentCode(documentToUpdate.code);
            setShowDocumentsList(false);
            setShowCreateForm(false);
            setShowUpdateForm(true);
            setUpdateDocumentType(documentToUpdate.type);
            setUpdateDocumentCode(documentToUpdate.code);
            setImageUrl(documentToUpdate.documentUrl);
        } else {
            console.error('Güncellenecek belge bulunamadı.');
        }
    };

    useEffect(() => {
        const getDocuments = async () => {
            try {
                const documentData = await Repository.getAppDocuments() ?? [];
                setDocuments(documentData);

            } catch (error) {
                console.error('Belgeleri alırken bir hata oluştu:', error);
            }
        };

        getDocuments();
    }, []);

    const handleDeleteDocument = (documentId: string) => {
        setDeleteDocumentId(documentId);
        setOpenDeleteDialog(true);
    };
    const openCreateForm = () => {
        setShowCreateForm(true);
        setOpenDialog(true);
        setShowUpdateForm(false);
        setTitle('');
        setContent('');
        setDocumentCode('');
        setIsConfidential(false);
    };

    const toggleCreateForm = () => {
        setShowCreateForm(false);
        setShowUpdateForm(false);
        setShowDocumentsList(true);
        setTitle('');
        setContent('');
        setDocumentCode('');
        setIsConfidential(false);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            try {
                setFile(selectedFile);
                const documentUrl = await Repository.uploadDocument(selectedFile) ?? null;
                setImageUrl(documentUrl) ;
            } catch (error) {
                console.error('Dosya yükleme hatası:', error);
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDocumentTypeSelect = (type: string) => {
        setDocumentType(type);
        setOpenDialog(false);
    };

    return (
        <div style={{overflowY:'hidden'}} className='container-fluid'>
            <div className='row'>
                <div className='col-sm-2 backgroundDiv'>
                    <img src={bg} alt="Background" />
                </div>

                <div className='col-sm-10 content-admin'>
                    <div className='notification-title'>
                        <h1>Dökümanlar</h1>
                        {
                            (showCreateForm || showUpdateForm) ? (
                                <button className='new-notification' onClick={toggleCreateForm}>
                                    <><FaTimes style={{ marginRight: '5px', color: 'red' }} /> Vazgeç</>
                                </button>
                            ) : (
                                <button className='create-notification' onClick={openCreateForm}>
                                    <><FaPaperPlane style={{ marginRight: '5px' }} /> Yeni Oluştur</>
                                </button>
                            )
                        }
                    </div>
                    <hr style={{ width: '95%' }} />

                    {showCreateForm ? (
                        <Grid container spacing={2} alignItems="center" style={{ paddingRight: '35px', display: 'block' }}>
                            <Grid item xs={12}>
                                {/* <Typography variant="h6">Yeni Döküman Oluştur</Typography> */}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Döküman Başlığı"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Döküman Kodu"
                                    value={documentCode}
                                    onChange={(e) => setDocumentCode(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            {documentType === 'html' && (
                                <Grid item xs={12}>
                                    <TextField
                                        label="Döküman İçeriği"
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                            {documentType === 'pdf' && (
                                <Grid className='pdfUpload' item xs={12}>
                                    <input type="file" onChange={handleFileChange} accept=".pdf" />
                                </Grid>
                            )}
                            <div>
                                {
                                    documentType === 'pdf' && (
                                        <label style={{ color: '#1D1E2599', marginTop: '10px', fontWeight: '200' }}>* Dosya yüklemek zorunlu değildir.</label>
                                    )
                                }

                            </div>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={isConfidential} onChange={(e) => setIsConfidential(e.target.checked)} />}
                                    label="Bu döküman uygulamada görünsün"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='create-notification-button' variant="contained" onClick={handleCreateDocument}><img src={arrow} alt="Arrow" />Kaydet</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <ul>

                            {showDocumentsList && documents && documents.length > 0 ? (
                                documents.map((document, index) => (
                                    <li className='notifications' key={index}>
                                        <div className='documents'>
                                            <h3 className='notifications-title'>{document.title}</h3>
                                            <div className='documents-buttons'>
                                                <button style={{ marginRight: '10px', border: '1px solid red', color: 'red' }} onClick={() => handleDeleteDocument(document.code)}>Sil</button>
                                                <button style={{ border: '1px solid #b9e7f7', color: '#b9e7f7' }}
                                                    onClick={() => openUpdateForm(document.id)}>Güncelle</button>
                                            </div>

                                        </div>
                                        <p className='notifications-creation-date'>{document.type === 0 ? 'PDF' : 'HTML'}</p>
                                        <hr style={{ width: '95%' }} />
                                    </li>
                                ))
                            ) : (showDocumentsList && (
                                <div className='no-notification'>
                                    <h1>😉</h1>
                                    <br />
                                    <h4>Döküman Yok</h4>
                                    <p>Şu anda görüntülenecek bir döküman yok.</p>
                                </div>)
                            )}
                        </ul>
                    )}

                    {showUpdateForm === true ? (
                        <Grid container spacing={2} alignItems="center" style={{ paddingRight: '35px', display: 'block' }}>
                            <Grid item xs={12}>
                                {/* <Typography variant="h6">Dökümanı Güncelle</Typography> */}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Döküman Başlığı"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled
                                    label='Döküman Kodu'
                                    value={documentCode}
                                    onChange={(e) => setDocumentCode(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            {updateDocumentType === 1 && (
                                <Grid item xs={12}>
                                    <TextField
                                        label="Döküman İçeriği"
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                            {updateDocumentType === 0 && (
                                <Grid className='pdfUpload' item xs={12}>
                                    {imageUrl && (
                                        <div>
                                            <Typography>Mevcut Dosya:</Typography>
                                            <a href={`${cdnBaseUrl}${imageUrl}`} target="_blank" rel="noopener noreferrer">PDF Dosyasını Görüntüle</a>
                                        </div>
                                    )}
                                    <input type="file" onChange={handleFileChange} accept=".pdf" />
                                   
                                </Grid>
                            )}
                            <div>
                                {
                                    updateDocumentType === 0 && (
                                        <label style={{ color: '#1D1E2599', marginTop: '10px', fontWeight: '200' }}>* Dosya yüklemek zorunlu değildir.</label>
                                    )
                                }

                            </div>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={isConfidential} onChange={(e) => setIsConfidential(e.target.checked)} />}
                                    label="Bu döküman uygulamada görünsün"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='create-notification-button' variant="contained" onClick={handleUpdate}><img src={arrow} alt="Arrow" />Güncelle</Button>
                            </Grid>
                        </Grid>
                    ) : ''

                    }
                </div>
            </div>
            {/* Pop-up penceresi */}
            <Dialog className='document-selection' open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle className='dialogTitle'>Döküman Oluştur</DialogTitle>
                <DialogContent>
                    <label>Oluşturacağınız döküman PDF mi yoksa HTML mi olacak?</label>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDocumentTypeSelect('pdf')}>PDF</Button>
                    <Button onClick={() => handleDocumentTypeSelect('html')}>HTML</Button>
                    <Button onClick={handleCloseDialog}>İptal</Button>
                </DialogActions>
            </Dialog>

            <Dialog className='document-selection' open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle className='dialogTitle'>Dökümanı Siliyorsunuz</DialogTitle>
                <DialogContent>
                    <Typography>Bu işlem geri alınamaz. Silmek istediğinize emin misiniz?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button style={{ color: 'red', border: '1px solid red' }} onClick={handleDeleteConfirmation}>Evet</Button>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Vazgeç</Button>
                </DialogActions>
            </Dialog>


        </div>
    );
}
