import axios, {AxiosError} from 'axios';
import baseUrl from './baseUrl';
import { Repository } from '../data/repository/repository';
import {routeLogin, RoutingHelper} from "../constants/routes";
import { toast } from 'react-toastify';
import {ApiErrorDTO} from "@/model/common/ApiErrorDTO";
import {Constants} from "../constants/constants";
import {Sleep} from "../helpers/Sleep";


let IS_REFRESHING = false;

const instance = axios.create({
    baseURL: baseUrl,
});


instance.interceptors.request.use(
    async (config) => {
        console.log(`making request ${config.url}`);
        const token = Repository.getToken();
        const guestToken = Repository.getGuestToken()

        if (config.url?.includes('users/guests/login') || config.url?.includes('api/companies/register') || config.url?.includes('users/sms-code/send') || config.url?.includes('users/sms-code/verify-code-common')) {
            config.headers['userId'] = Repository.getGuestUser()?.id
        }
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        else if (guestToken && config.url?.includes('api/companies/register') || config.url?.includes('users/sms-code/send') || config.url?.includes('users/sms-code/verify-code-common')) {
            config.headers['Authorization'] = `Bearer ${guestToken}`;
            config.headers['userId'] = Repository.getGuestUser()?.id;

        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



instance.interceptors.response.use(
    (response) => {
        console.log(`succes on request : ${response.config.url}`);
        console.log(response);
        return response;
    },
    async (error) => {
        return handleResponseError(error);
    }
)


async function handleResponseError(error: AxiosError) {
    if (error.response && error.response.status === 401) {

        if(!IS_REFRESHING) {
            IS_REFRESHING = true;
            if (Repository.hasValidSession() && !error.config?.headers['isRetry']) {
                error.config!.headers['isRetry'] = true;
                try {
                    const refreshResult = await Repository.refreshToken()
                    const originalRequest = error.config;
                    originalRequest!.headers['Authorization'] = `Bearer ${refreshResult?.jwtToken ?? ''}`;
                    return axios(originalRequest!);
                }
                catch (refreshError: any) {
                    console.error(refreshError.message);
                    if (refreshError.response && (refreshError.response.status === 401 || refreshError.response.status === 404)) {
                        Repository.clearSessionData()
                        console.log('refresh token failed. redirecting to the login page..')
                        RoutingHelper.navigateByUrl(routeLogin)
                    }
                    return Promise.reject(refreshError);
                }
            }
            else {
                Repository.clearSessionData()
                console.log('401 after retry. redirecting to the login page..')
                RoutingHelper.navigateByUrl(routeLogin)
            }
            IS_REFRESHING = false;
        }
        else {
            let totalWait = 0
            while (IS_REFRESHING && totalWait < Constants.AWAIT_REFRESH_TOKEN_MAX_DURATION) {
                console.warn(`Sleeping trough refresh token for request : ${error.config?.url}`)
                await Sleep.for(Constants.AWAIT_REFRESH_TOKEN_GRANULARITY)
                totalWait += Constants.AWAIT_REFRESH_TOKEN_GRANULARITY
            }

            const originalRequest = error.config;
            originalRequest!.headers['Authorization'] = `Bearer ${Repository.getToken() ?? ''}`;
            return  axios(originalRequest!);
        }

    }
    else {
        if(error?.response?.data) {
            const apiError = error.response.data as ApiErrorDTO
            if(apiError.showMessage) {
                toast.error(`${apiError.message}`);
            }
        }

        return Promise.reject(error);
    }

}






export default instance;
